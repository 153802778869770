// extracted by mini-css-extract-plugin
export var container = "ChooseLocalityForm-module--container--Jmni9";
export var dNone = "ChooseLocalityForm-module--d-none--p1N54";
export var formHead = "ChooseLocalityForm-module--formHead--spPM9";
export var icon = "ChooseLocalityForm-module--icon--joHB2";
export var input = "ChooseLocalityForm-module--input--NQrns";
export var invalid = "ChooseLocalityForm-module--invalid--SpUKr";
export var longContainerFluid = "ChooseLocalityForm-module--longContainerFluid--uHVgT";
export var region = "ChooseLocalityForm-module--region--QKriK";
export var root = "ChooseLocalityForm-module--root--eAvcv";
export var valid = "ChooseLocalityForm-module--valid--58OQN";