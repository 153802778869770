import React, { FC } from "react"

import FormLanding from "@ecom/ui/components/FormLanding"
import Container from "@ecom/ui/components/Container"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"

import clsx from "clsx"
import CircleContainer from "../CircleContainer/CircleContainer"
import * as styles from "./LongAppWrapper.module.scss"

import type { LongAppWrapperProps } from "./types"

const LongAppWrapper: FC<LongAppWrapperProps> = ({
  children,
  onChangeFields,
  refForm,
  title,
  name = "longPersonalForm",
  handleSubmit,
  isDisabled,
  stepIcon,
  withSteps,
  btnText = "Дальше",
  variant = "default",
}) => (
  <Container className={styles.root}>
    <FormLanding name={name} onSubmit={handleSubmit} onChangeFields={onChangeFields} ref={refForm}>
      <div className={withSteps && styles.headBlock}>
        <div className={styles.title}>{title}</div>
        {withSteps && stepIcon && <div className={styles.progress}>{stepIcon}</div>}
      </div>
      <CircleContainer className={clsx(styles.container, styles[variant])}>
        {children}
      </CircleContainer>
      <div className={styles.btnGroup}>
        {/* <BackButton className={styles.back} color="#003791" /> */}
        <SubmitButton disabled={isDisabled} className={styles.next} color="light">
          {btnText}
        </SubmitButton>
      </div>
    </FormLanding>
  </Container>
)

export default LongAppWrapper
