// extracted by mini-css-extract-plugin
export var back = "LongAppWrapper-module--back--PQuQe";
export var btnGroup = "LongAppWrapper-module--btnGroup--7TOlu";
export var container = "LongAppWrapper-module--container--Zwk59";
export var custom = "LongAppWrapper-module--custom--zP0zl";
export var headBlock = "LongAppWrapper-module--headBlock--dKcy+";
export var next = "LongAppWrapper-module--next--1wFgH";
export var none = "LongAppWrapper-module--none--myOUW";
export var progress = "LongAppWrapper-module--progress--f9oes";
export var root = "LongAppWrapper-module--root--uh49C";
export var title = "LongAppWrapper-module--title--r4T7i";