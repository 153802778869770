import formatToRequest from "@ecom/ui/helpers/formatToRequest"

import type { LongAppFormData, LongAppFormDataFormatted } from "./types"

const fieldsType = {
  region: "region",
  localityAddress: "dadataAddress",
}

export const LOCALITY_DADATA_OPTIONS = {
  count: 10,
  from_bound: { value: "city" },
  restrict_value: true,
  to_bound: { value: "settlement" },
}
export const EMPTY_REGION_VALUE = { label: "", value: "", kladr_id: "" }

export function formatValuesToRequest(values: LongAppFormData): LongAppFormDataFormatted {
  const valuesToRequest = formatToRequest(values, fieldsType)

  return valuesToRequest
}
